import React, { Component } from 'react';
import {
    Grid,
    Typography,
    Icon,
    IconButton,
    Button,
    CircularProgress
} from '@material-ui/core';
import './Judge.scss';
import PlayingCard from './Card';

const JUDGE_PHRASES = [
    "ZAP!",
    "BAM",
    "POW!",
    "WHAM"
];


class JudgeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentBlindDate: 0,
            viewedBlindDates: [],
            awaitUpdate: false,
        };
        this.randomJudgePhrase = JUDGE_PHRASES[Math.floor(Math.random() * JUDGE_PHRASES.length)];
    }

    render() {
        if (this.props.heroes=== []) {
            return (<div></div>);
        }

        const blindDates = this.props.heroes;
        const currentBlindDate = this.state.currentBlindDate;
        const isJudge = this.props.game.canJudge;
        const buttonText = isJudge ? this.randomJudgePhrase : 'Judging...';
        const disableFlip = this.state.viewedBlindDates.includes(currentBlindDate);

        return (
            <Grid container justify="space-evenly" className="judge-container">
                <IconButton onClick={this.handleBack} className="back-btn" disabled={currentBlindDate === 0}>
                    <Icon>forward</Icon>
                </IconButton>
                <Grid container alignItems="center" justify="center" direction="column" className="center-container">                   
                    <Typography variant="h4" className="player-title">
                        <b>
                            {blindDates[currentBlindDate].player.name}
                        </b>'s hero is...
                    </Typography>
                    
                    <Grid container justify="space-evenly" className="judge-card-container">
                        <PlayingCard 
                            key={'character-0'}
                            card={{description: blindDates[currentBlindDate].character}} 
                            character
                            flipDelay={500}
                            disableFlip={disableFlip}
                            disableClick={true} />
                        <PlayingCard 
                            key={'attribute-0'}
                            card={{description: blindDates[currentBlindDate].attribute0}} 
                            flipDelay={700}
                            disableFlip={disableFlip}
                            disableClick={true} />
                        <PlayingCard 
                            key={'attribute-1'}
                            card={{description: blindDates[currentBlindDate].attribute1}}
                            flipDelay={900}
                            disableFlip={disableFlip}
                            disableClick={true} />
                    </Grid>

                    <Button variant="contained"
                            color="primary"
                            className="judge-btn"
                            disabled={this.state.awaitUpdate || !isJudge}
                            onClick={() => {this.handleJudgement()}}>
                        {this.state.awaitUpdate ?
                            (<CircularProgress />) :
                            (buttonText)
                        }
                    </Button>
                </Grid>
                <IconButton onClick={this.handleNext} className="next-btn" disabled={currentBlindDate === blindDates.length-1}>
                    <Icon>forward</Icon>
                </IconButton>
            </Grid>
        )
    };

    handleBack = (event) => {
        if (this.currentBlindDate <= 0) {
            return;
        }

        let visitedDates = this.state.viewedBlindDates;
        if (!visitedDates.includes(this.state.currentBlindDate)) {
            visitedDates = visitedDates.concat(this.state.currentBlindDate);
        }
        this.setState({
            currentBlindDate: this.state.currentBlindDate - 1,
            viewedBlindDates: visitedDates
        });
    };

    handleNext = (event) => {
        if (this.currentBlindDate >= this.props.heroes.length-1) {
            return;
        }

        let visitedDates = this.state.viewedBlindDates;
        if (!visitedDates.includes(this.state.currentBlindDate)) {
            visitedDates = visitedDates.concat(this.state.currentBlindDate);
        }
        this.setState({
            currentBlindDate: this.state.currentBlindDate + 1,
            viewedBlindDates: visitedDates
        });
    };

    async handleJudgement() {
        this.setState({awaitUpdate: true});
        const winner = this.props.heroes[this.state.currentBlindDate].playerIndex;
        this.props.onJudge(winner);
    }
}
export default JudgeComponent