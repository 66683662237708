import React, { Component } from 'react';
import './App.scss';
import { withRouter, useParams } from 'react-router-dom';
import service from './GameService';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, Grid, Typography, Icon, Grow, Input } from '@material-ui/core';
import Players from './Players';
import Judge from './Judge';
import FullscreenButton from './Fullscreen';
import PlayingCard from './Card';
import Scoreboard from './Scoreboard';
import './Game.scss';
import WinnerDialog from './WinnerDialog';

const POLL_PERIOD = 2000 // milliseconds
const PHASE_INIT = "INIT"
const PHASE_JUDGE = "JUDGE"
const PHASE_PLAY = "PLAY"
const PHASE_OVER = "OVER"
const PHASE_REVIEW = "REVIEW"
const NUM_PLAYER_COLORS = 12;


class GameComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            game: undefined,
            gameId: props.gameId,
            found: true,
            errorMessage: undefined,
            //mySub: undefined,
            intervalId: undefined,
            awaitUpdate: false,
            characters: [],
            attributes: [],
            selectedPerks: [],
            selectedFlags: [],
            isGuideComplete: true,
            gameSettings: {},
            showFireworks: true,
        }
        this.poll = this.poll.bind(this);
        this.handleJoinGame = this.handleJoinGame.bind(this)
        this.handleStartGame = this.handleStartGame.bind(this)
        this.handleAddBot = this.handleAddBot.bind(this)
        this.updateGameState = this.updateGameState.bind(this)
        this.handleOnReady = this.handleOnReady.bind(this)
        this.awaitUpdate = this.awaitUpdate.bind(this)
        this.handleOnJudge = this.handleOnJudge.bind(this)
        this.handleFinalizePerks = this.handleFinalizePerks.bind(this)
    }

    async componentDidMount() {
        try {
            const [game, characters, attributes] = [...await Promise.all([
                service.getGame(this.state.gameId),
                service.characters(),
                service.attributes()
            ])]

            if (game.message !== undefined) {
                this.setState({
                    errorMessage: game.message
                })
            }
            else {
                const playerColorMap = game.players.map(assignColors);
                const totalScore = game.players.map(p => p.points).reduce((a,b) => a + b);
                const newState = {
                    game,
                    characters,
                    attributes,
                    found: true,
                    errorMessage: undefined,
                    playerColorMap,
                    isGuideComplete: totalScore > 0,
                    gameSettings: {
                        points: game.points
                    },
                }

                this.setState(newState)
                if (!game.over) {
                    let intervalId = setInterval(this.poll, POLL_PERIOD)
                    this.setState({ intervalId })
                }
            }
        }
        catch (e) {
            console.log(e)
            this.setState({ found: false })
        }
    }

    componentWillUnmount() {
        this.clearPolling()
    }

    render() {
        if (!this.state.found || this.state.gameId === undefined) {
            return (<p>{this.state.gameId} Not Found!</p>)
        }
        else if (this.state.errorMessage !== undefined) {
            return (<p>{this.state.errorMessage} Not Found!</p>)
        }
        else if (this.state.game === undefined) {
            return (
                <div className="loading-screen">
                    <CircularProgress size="80px" />
                </div>
            )
        }

        if (this.state.game.phase === PHASE_INIT) {
            return (
                <div className="game-container game-not-started">
                    <Grid container alignItems="center" justify="space-evenly" direction="column" style={{'minHeight': '80%'}}>
                        {this.join()}
                        {this.start()}
                        <Grid container alignItems="flex-start" justify="space-evenly">
                            {this.updateSettings()}
                            <Players game={this.state.game} playerColorMap={this.state.playerColorMap}/>
                        </Grid>
                        {this.addBot()}
                    </Grid>
                    <FullscreenButton white></FullscreenButton>
                </div>
            )
        }

        if (!this.state.isGuideComplete) {
            const transitionTimeout = {
                appear: 0,
                enter: 2000
            };

            return (
                <div className="game-container game-not-started">
                    <Grid container alignItems="center" justify="space-evenly" direction="column" className="guide-container">
                        <Typography variant="h2" className="guide-title">
                            How To Play
                        </Typography>
                        <Grow in={true} timeout={transitionTimeout}>
                            <Typography variant="h5">
                                <img src="/assets/logo.png" alt="logo"></img>
                                <b>Setup: </b>
                                Two players each create a <b><u>hero</u></b> to fight, and everyone else votes on the winner
                            </Typography>
                        </Grow>
                        <Grow in={true} timeout={transitionTimeout}  style={{transitionDelay: 2000}}>
                            <Typography variant="h5">
                                <img src="/assets/logo.png" alt="logo"></img>
                                <b>The Character: </b>
                                The sentient being who will become your <b><u>hero</u></b>
                            </Typography>
                        </Grow>
                        <Grow in={true} timeout={transitionTimeout} style={{transitionDelay: 4000}}>
                            <Typography variant="h5">
                                <img src="/assets/logo.png" alt="logo"></img>
                                <b>The Traits: </b>
                                Choose a weapon, super power, or even a weakness to grant your <b><u>hero</u></b>. A second trait will be randomly assigned to your <b><u>hero</u></b>
                            </Typography>
                        </Grow>
                        <Grow in={true} timeout={transitionTimeout} style={{transitionDelay: 6000}}>
                            <Typography variant="h5">
                                <img src="/assets/logo.png" alt="logo"></img>
                                <b>Debate: </b>
                                Once both <b><u>heroes</u></b> have been picked, both players will argue why the their <b><u>hero</u></b> would win a fight against the other
                            </Typography>
                        </Grow>
                        <Grow in={true} timeout={transitionTimeout} style={{transitionDelay: 8000}}>
                            <Typography variant="h5">
                                <img src="/assets/logo.png" alt="logo"></img>
                                <b>Selection: </b>
                                Based on the arguments, the everyone else votes on a winner. Ties go to the reigning champion
                            </Typography>
                        </Grow>
                        <Grow in={true} timeout={transitionTimeout} style={{transitionDelay: 10000}}>
                            <Typography variant="h5">
                                <img src="/assets/logo.png" alt="logo"></img>
                                <b>Retirement: </b>
                                The winner of each round goes on to fight a new challenger in the next. If a hero wins 3 fights in a row, a new hero is created to take over
                            </Typography>
                        </Grow>
        
                        <Button variant="contained"
                                className="skip-guide-btn"
                                onClick={() => {this.setState({isGuideComplete: true})}}>
                            Got it, let's go!
                        </Button>
                    </Grid>
                    <FullscreenButton white></FullscreenButton>
                </div>
            );
        }

        return (
            <div className="game-container">
                {this.handleJudgementPhase()}
                {this.handlePlayPhase()}
                {this.handleScoreboardPhase()}
                <FullscreenButton></FullscreenButton>
            </div>
        )
    }
    
    handleJudgementPhase = () => {
        if (this.state.game.phase !== PHASE_JUDGE) {
            return (null);
        }
        const players = this.state.game.players;
        const heroes = [this.state.game.champion.player, this.state.game.currentPlayer].map(playerIndex => {
            return {
                player: players[playerIndex],
                playerIndex: playerIndex,
                character: this.state.characters[players[playerIndex].playedCharacter],
                attribute0: this.state.attributes[players[playerIndex].playedAttribute],
                attribute1: this.state.attributes[players[playerIndex].assignedAttribute],
            };
        });

        return (
            <Judge
                game={this.state.game}
                heroes={heroes}
                onJudge={this.handleOnJudge}
            />
        );
    }

    handleScoreboardPhase = () => {
        if (this.state.game.phase !== PHASE_OVER && this.state.game.phase !== PHASE_REVIEW) {
            return (null)
        }

        // If game is over, show who won
        if (this.state.game.phase === PHASE_OVER) {
            return (
                <Grid container alignItems="center" justify="space-evenly" direction="column" className="game-over-container">
                    <Scoreboard game={this.state.game}/>
                    <Grid container justify="center" alignItems="center" direction="column" className="winner-section">
                        <Typography variant="h4">
                            <b>Game Over</b>
                        </Typography>
                        <Typography variant="h4" style={{textAlign:'center'}}>
                            Congratulations to the best hero maker, <b>
                                {[...this.state.game.players].sort((a,b) => b.points - a.points)[0].name}
                            </b>!
                        </Typography>
                    </Grid>
                    <div className="fireworks">
                        {/* 
                            Executing raw jQuery based on current state is non-trivial in React.
                            I tried a few different approaches, but ultimately hacked my way through by
                            injecting javascript via onError of a 'bad' image.

                            We need to only call .fireworks() once, else the browser suffers serious
                            performance issues.
                        */}
                        <img src='x' alt='' onError={() => {
                            if (this.state.showFireworks) {
                                window.$('.fireworks').fireworks({ count: 5 });
                                this.setState({showFireworks: false});
                            }
                        }}></img>
                    </div>
                    <WinnerDialog
                        game={this.state.game}
                        characters={this.state.characters}
                        attributes={this.state.attributes}>
                    </WinnerDialog>
                </Grid>
            )
        }

        // If you are ready, show which players are not
        // else, show the previous winner and the scoreboard
        if (this.state.game.players[this.state.game.youAre].ready) {
            const statusIcon = (player) => {
                if (player.ready) {
                    return <Icon className="ready-check">check_circle</Icon>
                }

                return <CircularProgress size="40px" className="progress-status"/>
            };

            const status = this.state.game.players.map((player, playerIndex) => (
                <Grid
                    container
                    alignItems="center"
                    justify="center"
                    key={`ready-${playerIndex}`}
                    className="player-status"
                >
                    {statusIcon(player)}
                    <Typography variant="h4">
                        {player.name}
                    </Typography>
                </Grid>
            ));

            return (
                <Grid container alignItems="center" justify="center" direction="column" className="play-container">
                    <Typography variant="h4" className="player-title">
                        Please hold tight while your friends get ready
                    </Typography>
                    {status}
                </Grid>
            )
        } else {
            return (
                <Grid container alignItems="center" justify="space-evenly" direction="column">
                    <Scoreboard game={this.state.game}/>
                    <Button variant="contained"
                            className="next-round-btn"
                            disabled={this.state.awaitUpdate}
                            onClick={() => {this.handleOnReady()}}>
                        {this.state.awaitUpdate ?
                            (<CircularProgress />) :
                            ('Start Next Round')
                        }
                    </Button>
                    <WinnerDialog
                        game={this.state.game}
                        characters={this.state.characters}
                        attributes={this.state.attributes}>
                    </WinnerDialog>
                </Grid>
            )
        }
    }
    
    handlePlayPhase = () => {
        if (this.state.game.phase !== PHASE_PLAY) {
            return (null);
        }
        
        const players = this.state.game.players;
        const myself = players[this.state.game.youAre];
        const opponent = this.state.game.youAre === this.state.game.currentPlayer ? 
                players[this.state.game.champion.player].name : players[this.state.game.currentPlayer].name;
        //const characterCards = [];
        //const attributeCards = [];
        //const isPerkPhase = myself.playedPerks.length === 0;

        // Check if you are a judge this round, or a champion with nothing to add
        if (this.state.game.players[this.state.game.youAre].characters.length === 0) {
            const competitors = [
                this.state.game.players[this.state.game.champion.player], 
                this.state.game.players[this.state.game.currentPlayer],
            ];
            const statusIcon = (player) => {
                if (player.playedAttribute !== -1 && player.playedCharacter !== -1) {
                    return (
                        <Icon className="ready-check">check_circle</Icon>
                    )
                } else {
                    return (
                        <CircularProgress size="40px" className="progress-status"/>
                    )
                }
            };

            const status = competitors.map(player => (
                <Grid
                    container
                    alignItems="center"
                    justify="center"
                    key={`ready-${player.name}`}
                    className="player-status"
                >
                    {statusIcon(player)}
                    <Typography variant="h4">
                        {player.name}
                    </Typography>
                </Grid>
            ));

            return (
                <Grid container direction="column" alignItems="center" justify="center" className="play-container">
                    <Typography variant="h4" className="player-title">
                        Please hold tight while the heroes assemble!
                    </Typography>
                    {status}
                </Grid>
            )
        }

        // you need to contribute somethign to this round
        const attributeCards = myself.attributes.map(attribute => {
            return {
                index: attribute,
                key: `attribute-${attribute}`,
                description: this.state.attributes[attribute] 
            };
        });

        const characterCards = myself.characters.map(character => {
            return {
                index: character,
                key: `character-${character}`,
                description: this.state.characters[character] 
            };
        });

        const finalizeButton = (
                <Button variant="contained"
                        color="primary"
                        className="select-perks-btn"
                        disabled={this.state.awaitUpdate || this.state.selectedPerks.length !== 1 || this.state.selectedFlags.length !== 1}
                        onClick={() => {this.handleFinalizePerks()}}>
                    {this.state.awaitUpdate ?
                        (<CircularProgress />) :
                        ('Select Hero')
                    }
                </Button>);

        const instructions = 'Pick a character and an attribute';

        return (
            <Grid container direction="column" alignItems="center" justify="center" className="play-container">
                <Typography variant="h4" className="player-title">
                    <b>{opponent}</b> is looking for a worthy opponent
                </Typography>
                <Typography variant="h4" className="instructions">
                    {instructions}
                </Typography>
                <Grid container justify="center" alignItems="center" direction="column" className="cards-container">
                    <Grid container justify="space-evenly" className="perk-container">
                        {characterCards.map((card, cardIndex) => {
                            return (
                                <PlayingCard
                                    key={card.key}
                                    card={card}
                                    character
                                    flipDelay={cardIndex * 400 + 300}
                                    onSelect={this.handlePerkSelected}
                                    selectedCards={this.state.selectedPerks} />
                            )
                        })}
                    </Grid>
                    <Grid container justify="space-evenly">
                        {attributeCards.map((card, cardIndex) => {
                            return (
                                <PlayingCard
                                    key={card.key}
                                    card={card}
                                    flipDelay={cardIndex * 400 + (characterCards.length  * 400) + 300}
                                    onSelect={this.handleFlagSelected}
                                    selectedCards={this.state.selectedFlags} />
                            )
                        })}
                    </Grid>
                </Grid>
                {finalizeButton}
            </Grid>
        );
    }

    handlePerkSelected = (perk) => {
        this.setState({
            selectedPerks: [perk]
        });
    }

    handleFlagSelected = (flag) => {
        this.setState({
            selectedFlags: [flag]
        });
    }

    join() {
        if (this.state.game.canJoin) {
            return (
                <Button variant="contained"
                        className="join-game-btn"
                        disabled={this.state.awaitUpdate}
                        onClick={() => {this.handleJoinGame()}}>
                    {this.state.awaitUpdate ?
                        (<CircularProgress />) :
                        ('Join')
                    }
                </Button>
            )
        }
        else {
            return (null)
        }
    }

    start() {
        if (this.state.game.canStart) {
            return (
                <Button variant="contained"
                        className="start-game-btn"
                        disabled={this.state.awaitUpdate}
                        onClick={() => {this.handleStartGame()}}>
                    {this.state.awaitUpdate ?
                        (<CircularProgress />) :
                        ('Start')
                    }
                </Button>
            )
        }
        else {
            return (null)
        }
    }

    updateSettings() {
        if (!this.state.game.canSetPoints) {
            return (
                <div className="section" style={{width: '50%'}}>
                    <Grid container alignItems="center" justify="center" direction="column">
                        <Typography variant="h4" className="settings-title">
                            Settings
                        </Typography>
                        <Typography variant="h5">
                            <b>Game Id: </b>{this.state.game.uuid}
                        </Typography>
                        <Typography variant="h5">
                            <b>Points To Win: </b>{this.state.game.points}
                        </Typography>
                    </Grid>
                </div>
            );
        }

        const handleInputChange = (event) => {
            this.setState({
                gameSettings: {
                    points: Number(event.target.value)
                }
            });
        };

        return (
            <div className="section" style={{width: '50%'}}>
                <Grid container alignItems="center" justify="center" direction="column">
                    <Typography variant="h4" className="settings-title">
                        Settings
                    </Typography>
                    <Typography variant="h5">
                        <b>Game Id: </b>{this.state.game.uuid}
                    </Typography>
                    <Typography variant="h5">
                        <b>Points To Win:</b>
                        <Input
                            className="points-input"
                            value={this.state.gameSettings.points}
                            margin="dense"
                            onChange={handleInputChange}
                            inputProps={{
                                step: 1,
                                min: 2,
                                max: 20,
                                type: 'number',
                            }}
                        />
                    </Typography>
                    <Button variant="contained"
                            className="update-settings-btn"
                            disabled={this.state.awaitUpdate}
                            onClick={() => {this.handleUpdateGameSettings()}}>
                        {this.state.awaitUpdate ?
                            (<CircularProgress />) :
                            ('Update Settings')
                        }
                    </Button>
                </Grid>
            </div>
        );
    }

    addBot() {
        if (this.state.game.phase === PHASE_INIT) {
            return (
                <Button variant="contained"
                        className="add-bot-btn"
                        disabled={this.state.awaitUpdate}
                        onClick={() => {this.handleAddBot()}}>
                    {this.state.awaitUpdate ?
                        (<CircularProgress />) :
                        ('Add Bot')
                    }
                </Button>
            )
        }
        else {
            return (null)
        }
    }

    async handleJoinGame() {
        try {
            this.awaitUpdate(async () => {
                return await service.joinGame(this.state.gameId)
            })
        }
        catch (e) {
            console.log(`${new Date().toISOString()}: ${e.message}`)
        }
    }

    async handleStartGame() {
        try {
            this.awaitUpdate(async () => {
                return await service.startGame(this.state.gameId)
            })
        }
        catch (e) {
            console.log(`${new Date().toISOString()}: ${e.message}`)
        }
    }

    async handleUpdateGameSettings() {
        try {
            this.awaitUpdate(async () => {
                return await service.updateGameSettings(this.state.gameId, this.state.gameSettings.points);
            })
        }
        catch (e) {
            console.log(`${new Date().toISOString()}: ${e.message}`)
        }
    }

    async handleAddBot() {
        try {
            this.awaitUpdate(async () => {
                return await service.addBot(this.state.gameId)
            })
        }
        catch (e) {
            console.log(`${new Date().toISOString()}: ${e.message}`)
        }
    }

    async handleOnJudge(winner) {
        try {
            this.awaitUpdate(async () => {
                return await service.judge(this.state.gameId, winner);
            })
        }
        catch (e) {
            console.log(`${new Date().toISOString()}: ${e.message}`);
        }
    }

    async handleFinalizePerks() {
        try {
            this.setState({ awaitUpdate: true });
            const resp = await service.playCharacterAndAttribute(
                this.state.gameId,
                this.state.selectedPerks[0],
                this.state.selectedFlags[0]
            )
            this.updateGameState(resp, true);
            this.setState({
                selectedPerks: [],
                selectedFlags: [],
            });
        }
        catch (e) {
            console.log(`${new Date().toISOString()}: ${e.message}`);
        }
    }

    async handleFinalizeFlags() {
        try {
            this.setState({ awaitUpdate: true });
            const resp = await service.playFlag(this.state.gameId, this.state.selectedFlags[0]);
            this.updateGameState(resp, true);
            this.setState({
                selectedFlags: []
            });
        }
        catch (e) {
            console.log(`${new Date().toISOString()}: ${e.message}`);
        }
    }

    async handleOnReady() {
        try {
            this.awaitUpdate(async () => {
                return await service.ready(this.state.gameId)
            })
        }
        catch (e) {
            console.log(`${new Date().toISOString()}: ${e.message}`)
        }
    }

    /**
     * Poll for updates to the game
     */
    async poll() {
        const myself = this.state.game.players[this.state.game.youAre];
        // don't poll if the user needs to provide an input
        const awaitingUserInput = (this.state.game.phase === PHASE_PLAY 
                &&  myself.characters.length > 0 && myself.playedCharacter === -1)
                || (this.state.game.phase === PHASE_REVIEW && !myself.ready)
                || (this.state.game.phase === PHASE_JUDGE && this.state.game.canJudge);
        if (!this.state.awaitUpdate && !awaitingUserInput) {
            try {
                const resp = await service.getGame(this.state.gameId)
                this.updateGameState(resp)
            }
            catch (e) {
                console.log(`${new Date().toISOString()}: ${e.message}`)
            }
        }
    }
    /**
     * Change game state as necessary
     * @param {Game} newGame
     * @param {boolean} gameStart - optional. if true, do dice init if appropiate
     */
    updateGameState(newGame, gameStart) {
        const newState = {
            game: newGame,
            awaitUpdate: false,
        }
        this.setState(newState)
        if (newGame.over) {
            this.clearPolling()
        }
    }
    /**
     * Callback should return a game object. Sets the awaitUpdate flag to true before 
     * service returns, then false when data is returned
     * @param {async Function<GameFull>} callback 
     */
    async awaitUpdate(callback) {
        this.setState({ awaitUpdate: true })
        this.updateGameState(await callback())
    }
    /**
     * Stops the interval call, as necessary. This should only happen if the game is ended
     */
    clearPolling() {
        if (this.state.intervalId !== undefined) {
            clearInterval(this.state.intervalId)
            this.setState({ intervalId: undefined })
        }
    }
}

function assignColors(p, pin) {
    return `playerColor${(pin % NUM_PLAYER_COLORS)}`
}

export { GameComponent }

export default function Game() {
    let { gameId } = useParams()
    const GameComponentWithRouter = withRouter(GameComponent)
    return (<GameComponentWithRouter gameId={gameId} />)
}
