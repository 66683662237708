import React, { Component } from 'react';
import {
    Grid,
    Dialog,
    DialogTitle,
    IconButton,
    Icon,
    Slide,
} from '@material-ui/core';
import './WinnerDialog.scss';
import PlayingCard from './Card';


class WinnerDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
        };
    }

    transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    render() {
        //const judge = this.props.game.players[this.props.game.lastWinner.judge];
        const winner = this.props.game.players[this.props.game.champion.player];
        const streak = this.props.game.champion.streak;
        const character = this.props.characters[this.props.game.champion.character];
        const attribute0 = this.props.attributes[this.props.game.champion.playedAttribute];
        const attribute1 = this.props.attributes[this.props.game.champion.assignedAttribute];

        return (
            <Dialog open={this.state.isOpen}
                    onClose={() => {this.setState({isOpen: false})}}
                    TransitionComponent={this.transition}
                    className="winner-dialog"
                    fullWidth={true}
                    maxWidth="md">

                <DialogTitle>
                    <b>{winner.name}</b>'s hero wins! Win streak: {streak}
                </DialogTitle>

                <Grid container>
                    <Grid container justify="space-evenly">
                        <PlayingCard 
                            key={'character-0'}
                            card={{description: character}} 
                            character
                            flipDelay={500}
                            disableClick={true} />
                        <PlayingCard 
                            key={'attribute-0'}
                            card={{description: attribute0}}
                            flipDelay={700}
                            disableClick={true} />
                        <PlayingCard 
                            key={'attribute-1'}
                            card={{description: attribute1}}
                            flipDelay={900}
                            disableClick={true} />
                    </Grid>
                </Grid>

                <IconButton className="close-btn" onClick={() => this.setState({isOpen: false})}>
                    <Icon>close</Icon>
                </IconButton>
            </Dialog>
        )
    };
}
export default WinnerDialogComponent