import { Auth } from 'aws-amplify'
const SERVICE_BASE_URL = getServiceUrl()

export default {
    getGame: async function(gameId) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}`, {
            headers: { ...(await this.authHeader()) },
        })
        return await resp.json()
    },

    createGame: async function() {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/create`, {
            method: 'POST',
            headers: { ...(await this.authHeader()) },
        })
        return await resp.json()
    },

    joinGame: async function(gameId) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/join`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
        })
        return await resp.json()
    },

    startGame: async function(gameId) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/start`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
        })
        return await resp.json()
    },

    addBot: async function(gameId) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/bot`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
        })
        return await resp.json()
    },
    /**
     * Plays 1 character for the round
     * @param {string} gameId 
     * @param {number} character
     */
    playCharacter: async function(gameId, character) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/character`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({character})
        })
        return await resp.json()
    },
    /**
     * Plays 1 attribute for the round
     * @param {string} gameId 
     * @param {number} attribute - a single number
     */
    playAttribute: async function(gameId, attribute) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/attribute`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({attribute})
        })
        return await resp.json()
    },
    /**
     * Plays 1 character and 1 attribute for the round
     * @param {string} gameId 
     * @param {number} character 
     * @param {number} attribute 
     */
    playCharacterAndAttribute: async function(gameId, character, attribute) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/characterAndAttribute`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({character, attribute})
        })
        return await resp.json()
    },
    /**
     * Picks a winner by it's player index.
     * Should be one of game.champion.player or game.currentPlayer
     * @param {string} gameId 
     * @param {number} winner - index of player in the players array
     */
    judge: async function(gameId, winner) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/judge`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({winner})
        })
        return await resp.json()
    },

    /**
     * Player signals they are ready to move to the next round
     * @param {string} gameId 
     */
    ready: async function(gameId) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/ready`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
        })
        return await resp.json()
    },

    /**
     * Update Game settings
     * @param {string} gameId 
     * @param {number} points - number of points for victory criteria
     */
    updateGameSettings: async function(gameId, points) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/points`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({points})
        })
        return await resp.json()
    },

    characters: async function() {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/characters`, {
            headers: { 
                ...(await this.authHeader()), 
            },
        })
        return await resp.json()
    },

    attributes: async function() {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/attributes`, {
            headers: { 
                ...(await this.authHeader()), 
            },
        })
        return await resp.json()
    },

    authHeader: async function() {
        const authdata = await Auth.currentSession()
        return {
            'Authorization': `Bearer ${authdata.getIdToken().getJwtToken()}`
        }
    },
}

function getServiceUrl() {
    return "https://gp3284upxc.execute-api.us-east-1.amazonaws.com/prod"
}
